import { useEffect, useRef, useReducer } from 'react';

export const useFetch = (url) => {
  const cache = useRef({});

  const initialState = {
    status: 'idle',
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) return;

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });
      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: 'FETCHED', payload: data });
      } else {
        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            cache.current[url] = data;
            if (cancelRequest) return;
            dispatch({ type: 'FETCHED', payload: data });
          } else {
            dispatch({ type: 'FETCH_ERROR', payload: response.status });
          }
        } catch (error) {
          if (cancelRequest) return;
          dispatch({ type: 'FETCH_ERROR', payload: error.message });
        }
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return state;
};

export const apiLogin = (data) => postData(getApiUrl("/api/v1/auth"), data);

export const apiGetUser = async (token) => {
  const url = getApiUrl(`/api/v1/user`, { token: token });
  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      return data.user;
    }
  } catch (error) {
    console.log(error)
  }
  return null;
}

export const apiGetUserById = async (id, token) => {
  const url = getApiUrl(`/api/v1/users/${id}`, { token: token });
  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      return data.user;
    }
  } catch (error) {
    console.log(error)
  }
  return null;
}

export const downloadZipFile = async (url, filename) => {
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/zip',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      var fileUrl = window.URL.createObjectURL(blob);
      // window.location.assign(fileUrl);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();  //afterwards we remove the element again
    }).catch((e) => {
      console.log(e)
    });
}

export const postData = async (url, data) => {
  // Default options are marked with *
  return fetch(url, {
    body: JSON.stringify(data), // must match 'Content-Type' header
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      // 'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/json'
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer
  });
}

export const getApiUrl = (path, { params = {}, token = '' } = {}) => {
  const esc = encodeURIComponent;
  params.t = token ?? '';
  const query = Object.keys(params ?? {})
    .map((k, v) => esc(k) + '=' + esc(params[k]))
    .join('&')
  // return `https://happy-class-zuowen-ofg4dor3eq-df.a.run.app${path}?${query}`
  // return `http://localhost:8000${path}?${query}`
  // return `https://happy.shuttleapp.rs${path}?${query}`
  return `https://happy-v8gv.shuttle.app${path}?${query}`
  // return `https://s.mincodes.com/apps/happyclass${path}?${query}`
}

export default useFetch;
